import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { DateTime } from 'luxon';
import { map, Observable, of, take, tap, zip } from 'rxjs';

import { CheckupProgressionDto } from '@smw/common-rest-dtos';

type Journey = {
  profileCompletedAt: DateTime | undefined;
  motivationsCompletedAt: DateTime | undefined;
  checkupCompletedAt: DateTime | undefined;
};

@Injectable({ providedIn: 'root' })
export class JourneyStore {
  constructor(private http: HttpClient) {}
  isLoaded = signal(false);

  journey = signal<Journey>({
    profileCompletedAt: undefined,
    motivationsCompletedAt: undefined,
    checkupCompletedAt: undefined,
  });

  isProfileCompleted = computed(() => !!this.journey().profileCompletedAt);
  isMotivationsCompleted = computed(() => !!this.journey().motivationsCompletedAt);
  isCheckupCompleted = computed(() => !!this.journey().checkupCompletedAt);
  isFullyCompleted = computed(
    () => this.isProfileCompleted() && this.isMotivationsCompleted() && this.isCheckupCompleted(),
  );

  loadJourney(): Observable<void> {
    if (this.isLoaded()) {
      return of();
    }

    return zip([
      this.http.get<CheckupProgressionDto>('/api/checkup/progression'),
      this.http.get('/api/profile/last-update', { responseType: 'text' }),
    ]).pipe(
      take(1),
      tap(([checkupProgression, profileUpdatedAt]) => {
        this.journey.set({
          checkupCompletedAt: checkupProgression.checkupCompletedAt
            ? DateTime.fromISO(checkupProgression.checkupCompletedAt, { zone: 'utc' })
            : undefined,
          motivationsCompletedAt: checkupProgression.motivationCompletedAt
            ? DateTime.fromISO(checkupProgression.motivationCompletedAt, { zone: 'utc' })
            : undefined,
          profileCompletedAt: profileUpdatedAt
            ? DateTime.fromISO(profileUpdatedAt, { zone: 'utc' })
            : undefined,
        });
        this.isLoaded.set(true);
      }),
      map(() => {}),
    );
  }
}
