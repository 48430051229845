<ul class="options" [class.empty]="isEmpty()">
  @for (option of options(); track option) {
    <li class="item">
      @if (multiple()) {
        <ng-container
          [ngTemplateOutlet]="multipleItem"
          [ngTemplateOutletContext]="{ option }"
        ></ng-container>
      } @else {
        <ng-container
          [ngTemplateOutlet]="simpleItem"
          [ngTemplateOutletContext]="{ option }"
        ></ng-container>
      }
    </li>
  } @empty {
    <li>
      <p class="body-sm text-primary-400 mb-0">
        {{ emptyMessage() ?? 'dropdown_empty_filter_conversation_manager_message' | translate }}
      </p>
    </li>
  }
</ul>

<ng-template #multipleItem let-option="option">
  <smw-checkbox
    [label]="simpleItem"
    [templateLabelContext]="{ option }"
    [checked]="isChecked(option)"
    [ngModel]="option.value"
    (ngModelChange)="itemSelected.emit(option.value)"
  ></smw-checkbox>
</ng-template>

<ng-template #simpleItem let-option="option">
  <div class="option" (click)="onClick(option.value)">
    <ng-container
      [ngTemplateOutlet]="customItemLabelTemplate() || defaultSimpleItem"
      [ngTemplateOutletContext]="{ option }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #defaultSimpleItem let-option="option">
  <span>{{ option?.label ?? '' }}</span>
</ng-template>
