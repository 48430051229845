import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  HostListener,
  input,
  OnDestroy,
  Renderer2,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, fromEvent, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'div[smw-editable], span[smw-editable]',
  standalone: true,
  imports: [],
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EditableComponent,
      multi: true,
    },
  ],
})
export class EditableComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  constructor(
    private elementRef: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
  ) {}

  debounce = input(1000);
  placeholder = input<string>();
  handlePlaceholder = effect(() => {
    const placeholder = this.placeholder();
    const value = this.value();
    const element = this.elementRef.nativeElement;
    if (placeholder && element && !value) {
      this.renderer.setAttribute(element, 'placeholder', placeholder);
    }
  });

  value = signal('');

  onChange!: (value: string) => void;
  onTouched!: () => void;
  onValidationChange!: () => void;

  private unsubscribe$ = new Subject<void>();

  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement;
    this.notifyChanges();

    this.renderer.setAttribute(element, 'contenteditable', 'true');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  writeValue(value: string): void {
    this.value.set(value);
    this.setContent(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', String(isDisabled));
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'contenteditable',
      String(!isDisabled),
    );
  }

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  @HostListener('click')
  private onClick() {
    this.elementRef.nativeElement.focus();
  }

  @HostListener('input')
  private onInput() {
    this.value.set(this.getContent());
  }

  @HostListener('blur')
  private onBlur() {
    this.onTouched?.();
  }

  /** Emit when user did not type for a given time (default to 1s) OR when the component is closed. */
  private notifyChanges() {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(debounceTime(this.debounce()), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.onChange(this.value());
        if (!this.value().length) {
          this.cleanUpEmptyContent();
        }
      });

    this.unsubscribe$.pipe(take(1)).subscribe(() => this.onChange(this.value()));
  }

  private cleanUpEmptyContent(): void {
    const content = this.getContent();
    if (!content) {
      this.setContent(''); // Ensure no residual nodes
      this.moveCursorToStart(); // Adjust caret position
    }
  }

  private moveCursorToStart(): void {
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(this.elementRef.nativeElement, 0);
    range.collapse(true);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  private getContent(): string {
    return this.elementRef.nativeElement.innerText.trim();
  }

  private setContent(value: string): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', value || '');
  }
}
