/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({ selector: '[notation]', standalone: true })
export class NotationColorDirective implements OnChanges {
  @Input() notation?: number | null;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(): void {
    let color = 'primary-400';

    if (this.notation != null) {
      if (this.notation <= 3) {
        color = 'secondary-red-500';
      } else if (this.notation >= 8) {
        color = 'secondary-green-500';
      } else {
        color = 'primary-500';
      }
    }

    this.renderer.setStyle(this.elementRef.nativeElement, 'color', `var(--${color})`);
  }
}
