<smw-auth-template>
  <div class="main">
    <h1 class="title-md mb-3">{{ 'login-as_auth_login_title' | translate }}</h1>

    <form class="flex flex-col">
      <input
        class="mb-6"
        [(ngModel)]="inputAdminEmail"
        type="email"
        name="adminEmail"
        [placeholder]="'login-as_input-email-placeholder' | translate"
      />

      <input
        class="mb-4"
        type="password"
        name="password"
        [(ngModel)]="inputAdminPassword"
        [placeholder]="'login-as_input-password-placeholder' | translate"
      />

      <input
        class="mb-4"
        [(ngModel)]="inputUserEmail"
        type="email"
        name="userEmail"
        [placeholder]="'login-as_input-user-email-placeholder' | translate"
      />
    </form>
  </div>

  <div class="actions">
    <button smw-button kind="filled" class="mb-10" (click)="connectAs()">
      {{ 'login-as_auth_login_action' | translate }}
    </button>
  </div>
</smw-auth-template>
