import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<Record<string, string>> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

// Register locals
registerLocaleData(localeFr);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      useDefaultLang: true,
      defaultLanguage: 'fr',
    }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  exports: [TranslateModule],
})
export class AngularTranslateModule {}
