import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
  pure: true,
  standalone: true,
})
export class SortByPipe implements PipeTransform {
  transform<T>(value: T[], property: keyof T): T[] {
    const copy = [...value];

    return copy.sort((itemA, itemB) => {
      if (itemA[property] < itemB[property]) {
        return -1;
      }

      if (itemA[property] > itemB[property]) {
        return 1;
      }

      return 0;
    });
  }
}
