<ng-container *ngFor="let w of rainW">
  <smw-icon
    icon="wLogo"
    [ngStyle]="{
      transform: 'rotate(' + w.rotate + 'deg)',
      left: w.left + '%',
      'animation-duration': w.speed + 's',
      color: w.color
    }"
  ></smw-icon>
</ng-container>
