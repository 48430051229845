import { ComponentRef, Directive, ElementRef, input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, fromEvent } from 'rxjs';

import { TooltipComponent } from './tooltip.component';
import { Overlay } from '../overlay/overlay';

@Directive({ standalone: true, selector: '[tooltip]' })
export class TooltipDirective implements OnDestroy {
  tooltip = input.required<string>();

  private isHovering = false;
  private componentRef?: ComponentRef<TooltipComponent>;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
  ) {
    fromEvent<MouseEvent>(document, 'mousemove')
      .pipe(debounceTime(10), takeUntilDestroyed())
      .subscribe((event) => this.onMove(event));
  }

  ngOnDestroy() {
    this.componentRef?.destroy();
    this.componentRef = undefined;
  }

  private onMove(event: MouseEvent) {
    const target = this.elementRef.nativeElement;
    const rect = target.getBoundingClientRect();

    const isInside =
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom;

    if (isInside && !this.isHovering) {
      this.isHovering = true;
      this.open();
    } else if (!isInside && this.isHovering) {
      this.isHovering = false;
      this.close();
    }
  }

  private open(): void {
    if (this.componentRef) {
      return;
    }

    this.componentRef = this.overlay.attachComponent(
      TooltipComponent,
      [
        { key: 'text', value: this.tooltip() },
        { key: 'show', value: true },
      ],
      {
        detachStrategy: { type: 'manual' },
        position: {
          relativeTo: this.elementRef.nativeElement,
          y: 'top-outer',
          x: 'left-inner'
        },
      },
    );
  }

  private close(): void {
    if (!this.componentRef) {
      return;
    }

    this.componentRef?.destroy();
    this.componentRef = undefined;
  }
}
