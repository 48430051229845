import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs';

import { LoggedAsBannerComponent } from '@smw/authentication-front';
import { isNavigationEnd } from '@smw/navigation-front';
import { toMediaQuery } from '@smw/utils-front';

import { FooterComponent } from '../footer';
import { HeaderComponent } from '../header';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  standalone: true,
  selector: 'smw-app-layout',
  imports: [
    RouterOutlet,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    LoggedAsBannerComponent,
  ],
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.less'],
  animations: [
    trigger('slideInLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [animate('250ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLayoutComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
  ) {}

  /** On small screen, allow to display/hide the sidebar when user clicks on menu burger */
  showSidebar = signal(false);

  /**
   * Sidebar should be add only on page where it is explicitly specified.
   * This is done by adding the data `{ sidebar: true }` on the route declaration
   */
  isSidebarEnabled = toSignal(
    this.router.events.pipe(
      filter(isNavigationEnd),
      map(() => this.useSidebar(this.route)),
    ),
    { initialValue: false },
  );

  shouldDisplaySidebar = computed(
    () =>
      this.isSidebarEnabled() &&
      (this.largeDevice() || this.mediumDevice() || (this.smallDevice() && this.showSidebar())),
  );

  smallDevice = toSignal(
    this.breakpointObserver
      .observe(toMediaQuery(['mobile', 'sm']))
      .pipe(map((result) => !!result.matches)),
    { initialValue: true },
  );
  mediumDevice = toSignal(
    this.breakpointObserver
      .observe(toMediaQuery(['md', 'lg']))
      .pipe(map((result) => !!result.matches)),
    { initialValue: true },
  );
  largeDevice = toSignal(
    this.breakpointObserver.observe(toMediaQuery(['xl'])).pipe(map((result) => !!result.matches)),
    { initialValue: false },
  );

  private useSidebar(route: ActivatedRoute): boolean {
    if (route.snapshot.data['sidebar'] === true) {
      return true;
    }

    for (const child of route.children) {
      return this.useSidebar(child);
    }
    return false;
  }

  closeSidebar(): void {
    this.showSidebar.set(false);

    const intercomElement = document.querySelector(
      '.intercom-lightweight-app-launcher',
    ) as HTMLElement;
    if (intercomElement) {
      intercomElement.style.display = 'block';
    }
  }

  openSidebar(): void {
    this.showSidebar.set(true);

    const intercomElement = document.querySelector(
      '.intercom-lightweight-app-launcher',
    ) as HTMLElement;
    if (intercomElement) {
      intercomElement.style.display = 'none';
    }
  }
}
