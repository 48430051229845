import { Directive, HostListener, input } from '@angular/core';

import { Overlay } from '../overlay/overlay';

@Directive({ selector: '[menuItem]', standalone: true })
export class MenuItemDirective {
  preventSelfClosing = input(false);

  constructor(private overlay: Overlay) {}

  @HostListener('click') onClick(): void {
    if (this.preventSelfClosing()) {
      return;
    }

    /** A small timeout is added to give time to process any actions triggered by a click on the menu item */
    setTimeout(() => {
      this.overlay.destroy();
    }, 100);
  }
}
