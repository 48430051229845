import { Route } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { AUTH_ROUTES } from '@smw/navigation-front';

import {
  ActivationComponent,
  LoginComponent,
  LostPasswordComponent,
  RegisterComponent,
  ResetPasswordComponent,
} from './pages';
import { LoginAsPageComponent } from './pages/login-as/login-as.component';

export const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'se-connecter',
  },
  {
    path: AUTH_ROUTES.register,
    pathMatch: 'full',
    component: RegisterComponent,
    title: marker('page-title-authentication'),
  },
  {
    path: AUTH_ROUTES.login,
    pathMatch: 'full',
    component: LoginComponent,
    title: marker('page-title-authentication'),
  },
  {
    path: AUTH_ROUTES.loginAs,
    pathMatch: 'full',
    component: LoginAsPageComponent,
    title: marker('page-title-authentication'),
  },
  {
    path: AUTH_ROUTES.lostPassword,
    pathMatch: 'full',
    component: LostPasswordComponent,
    title: marker('page-title-password-reset'),
  },
  {
    path: AUTH_ROUTES.resetPassword,
    pathMatch: 'full',
    component: ResetPasswordComponent,
    title: marker('page-title-password-reset'),
  },
  {
    path: AUTH_ROUTES.activate,
    pathMatch: 'full',
    component: ActivationComponent,
    title: marker('page-title-authentication'),
  },
];
