<smw-back class="absolute left-0"></smw-back>

<smw-auth-template>
  <div>
    <smw-icon class="logo" icon="logo"></smw-icon>

    <h1 class="title-md mb-3">{{ 'auth_lost-password_title' | translate }}</h1>

    @if (emailSent) {
      <p class="body-lg mb-10">{{ 'auth_lost-password_email-sent' | translate }}</p>
      <a smw-button kind="filled" class="mb-5" [routerLink]="['auth' | route]">{{
        'back-home' | translate
      }}</a>
    } @else if (resetPasswordErrorMessage() !== '') {
      <p class="body-lg mb-10 text-red-500 font-bold">
        {{ resetPasswordErrorMessage() | translate }}
      </p>
      <a smw-button kind="filled" class="mb-5" [routerLink]="['auth' | route]">{{
        'back-home' | translate
      }}</a>
    } @else {
      <p class="body-lg mb-0">{{ 'auth_lost-password_email' | translate }}</p>
      <form [formGroup]="form" *ngIf="form">
        <smw-textfield
          formControlName="email"
          [type]="'email'"
          [invalid]="isEmailInvalid()"
          [placeholder]="'input-email-placeholder' | translate"
        ></smw-textfield>
      </form>

      @if (isEmailInvalid()) {
        <p class="caption-sm text-secondary-red-500 email-error-message">
          {{ 'auth_lost-password_email_error' | translate }}
        </p>
      }

      <button smw-button kind="filled" class="confirm" [disabled]="isDisabled" (click)="submit()">
        {{ 'confirm' | translate }}
      </button>
    }
  </div>
</smw-auth-template>
