import { Injectable } from '@angular/core';

type TokenStorageKey = 'sharingToken' | 'authenticationToken';

class MemoryStorage {
  private storage = new Map<string, string>();

  setItem(key: string, value: string): void {
    this.storage.set(key, value);
  }

  getItem(key: string): string | undefined {
    return this.storage.get(key);
  }

  removeItem(key: string): void {
    this.storage.delete(key);
  }
}

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
  private memoryStorage = new MemoryStorage();

  store(key: TokenStorageKey, value: string): void {
    const storage = this.getStorageFor(key);
    storage.setItem(key, value);
  }

  get(key: TokenStorageKey): string | undefined {
    const storage = this.getStorageFor(key);
    return storage.getItem(key) ?? undefined;
  }

  delete(key: TokenStorageKey): void {
    const storage = this.getStorageFor(key);
    storage.removeItem(key);
  }

  private getStorageFor(key: TokenStorageKey) {
    return key === 'sharingToken' ? this.memoryStorage : localStorage;
  }
}
