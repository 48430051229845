import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalizeFirst', standalone: true, pure: true })
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: unknown) {
    if (typeof value !== 'string') {
      return value;
    }

    return value[0].toUpperCase() + value.slice(1);
  }
}
