import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '@smw/ui-front';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  selector: 'smw-auth-template',
  templateUrl: 'template.component.html',
  styleUrls: ['./template.component.less'],
})
export class AuthTemplateComponent {}
