import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { NavService } from '@smw/navigation-front';

import { AuthenticationService } from '../services';

export const anonymousGuard: CanActivateFn = () => {
  const router = inject(Router);
  const nav = inject(NavService);
  const authService = inject(AuthenticationService);

  return authService.isAuthenticated().pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return router.createUrlTree([nav.routeOf('collaborator')]);
      }

      return true;
    }),
  );
};
