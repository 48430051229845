import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  EnvironmentInjector,
  Injectable,
  InputSignal,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  TemplateRef,
  Type,
} from '@angular/core';
import { BehaviorSubject, skip, Subject, takeUntil } from 'rxjs';

import { OutsideClickListener } from '@smw/utils-front';

import { OverlayComponent } from './overlay.component';

type Ref<T> = ComponentRef<T> | EmbeddedViewRef<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputKeys<T> = { [K in keyof T]: T[K] extends InputSignal<any> ? K : never }[keyof T];
type InputValueType<T, K extends keyof T> = T[K] extends InputSignal<infer U> ? U : never;
type InputKeyValuePair<T> = {
  [K in InputKeys<T>]: { key: K; value: InputValueType<T, K> };
}[InputKeys<T>];

type DetachStrategy = ManualDetachStrategy | OutsideClickDetachStrategy | TimeoutDetachStrategy;
type ManualDetachStrategy = {
  type: 'manual';
};

type OutsideClickDetachStrategy = {
  type: 'outside-click';
};

type TimeoutDetachStrategy = {
  type: 'timeout';
  timeout: number;
};

export type XPosition = 'left-inner' | 'left-outer' | 'right-inner' | 'right-outer';
export type YPosition = 'top-inner' | 'top-outer' | 'bottom-inner' | 'bottom-outer';
type Position = { relativeTo: HTMLElement; x?: XPosition; y?: YPosition };

type AttachOption = {
  detachStrategy: DetachStrategy;
  position: Position;
};

function pxToRem(px: number): number {
  return px / 16;
}

@Injectable({ providedIn: 'root' })
export class Overlay implements OnDestroy {
  private renderer: Renderer2;
  private overlayRef?: ComponentRef<OverlayComponent>;

  private attachedRefCount$ = new BehaviorSubject<number>(0);
  private attachedRefs$ = new BehaviorSubject<Set<Ref<unknown>>>(new Set());

  private resizeObserver?: ResizeObserver;

  private unsubscribe$ = new Subject<void>();

  constructor(
    rendererFactory: RendererFactory2,
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    private outsideClickListener: OutsideClickListener,
  ) {
    this.renderer = rendererFactory.createRenderer(undefined, null);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  attachComponent<T>(
    component: Type<T>,
    inputs: InputKeyValuePair<T>[],
    options: AttachOption,
  ): ComponentRef<T> | undefined {
    const overlayRef = this.getOrCreateOverlayRef();

    const vcr = overlayRef.instance.containerRef();
    if (!vcr) {
      return;
    }

    const componentRef = vcr.createComponent(component, { environmentInjector: this.injector });
    inputs.forEach((input) => componentRef.setInput(input.key as string, input.value));

    /**
     * Allow to trigger change detections in component and setup view before positioning the items,
     * as the element may not have its full size before complete initialization.
     **/
    componentRef.changeDetectorRef.detectChanges();

    this.setupDetachStrategy(componentRef, options.detachStrategy);
    this.setElementPosition(componentRef.location.nativeElement, options.position);

    this.trackAttachedRef(componentRef);

    return componentRef;
  }

  attachTemplate<T = unknown>(
    template: TemplateRef<T>,
    options: AttachOption,
  ): EmbeddedViewRef<T> | undefined {
    const overlayRef = this.getOrCreateOverlayRef();

    const vcr = overlayRef.instance.containerRef();
    if (!vcr) {
      return;
    }

    const viewRef = vcr.createEmbeddedView(template);
    const element = viewRef.rootNodes[0];

    this.setupDetachStrategy(viewRef, options.detachStrategy);
    this.setElementPosition(element, options.position);
    this.observeResizeOf(element, options.position);

    this.trackAttachedRef(viewRef);

    return viewRef;
  }

  destroy(): void {
    if (!this.overlayRef) {
      return;
    }

    this.attachedRefs$.value.forEach((value) => value?.destroy());

    this.renderer.removeChild(document.body, this.overlayRef.location.nativeElement);
    this.overlayRef.destroy();
    this.overlayRef = undefined;
    this.resizeObserver?.disconnect();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupDetachStrategy(ref: Ref<unknown>, strategy: DetachStrategy) {
    switch (strategy.type) {
      case 'outside-click':
        this.detachOnOutsideClick(ref);
        break;
      case 'timeout':
        this.detachOnTimeout(ref, strategy.timeout);
        break;
      case 'manual':
        ref.onDestroy(() => this.untrackAttachRef(ref));
        break;
      default:
      // Do nothing
    }
  }

  private detachOnOutsideClick(ref: Ref<unknown>): void {
    const outsideClick$ = new Subject<void>();
    let element: HTMLElement | undefined;
    if (ref instanceof ComponentRef) {
      element = ref.location.nativeElement;
    } else {
      element = ref.rootNodes[0];
    }

    if (!element) {
      return;
    }

    ref.onDestroy(() => {
      // Trigger the subject to close the subscription if the ref is destroyed before there is an actual outside click
      this.untrackAttachRef(ref);
      outsideClick$.next();
    });

    this.outsideClickListener
      .clickedOutsideOf(element)
      .pipe(skip(1), takeUntil(outsideClick$))
      .subscribe(() => {
        ref.destroy();
        outsideClick$.next();
        outsideClick$.complete();
      });
  }

  private detachOnTimeout(ref: Ref<unknown>, timeout = 10_000): void {
    ref.onDestroy(() => this.untrackAttachRef(ref));
    setTimeout(() => {
      ref.destroy();
    }, timeout);
  }

  private getOrCreateOverlayRef(): ComponentRef<OverlayComponent> {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const ref = createComponent(OverlayComponent, { environmentInjector: this.injector });
    this.renderer.appendChild(document.body, ref.location.nativeElement);
    this.appRef.attachView(ref.hostView);

    this.overlayRef = ref;

    /** Destroy the container element when there is no more attached views. */
    this.attachedRefs$.pipe(skip(1), takeUntil(this.unsubscribe$)).subscribe((refs) => {
      if (refs.size === 0) {
        this.destroy();
      }
    });
    return ref;
  }

  private trackAttachedRef(ref: Ref<unknown>): void {
    this.attachedRefs$.next(this.attachedRefs$.value.add(ref));
  }

  private untrackAttachRef(ref: Ref<unknown>): void {
    const refs = this.attachedRefs$.value;
    refs.delete(ref);
    this.attachedRefs$.next(refs);
  }

  /**
   * Position an element relatively to another
   */
  private setElementPosition(element: HTMLElement, position: Position): void {
    const relativeRect = position.relativeTo.getBoundingClientRect();

    this.renderer.setStyle(element, 'position', 'absolute');
    this.positionHorizontally(element, relativeRect, position?.x);
    this.positionVertically(element, relativeRect, position?.y);
  }

  /**
   * The horizontal position of the element will be based on xPosition.
   * If not provided, the element will have a bottom position if enough place or top otherwise
   */
  private positionVertically(
    element: HTMLElement,
    relativeRect: DOMRect,
    position?: YPosition,
  ): void {
    const elementHeight = element.offsetHeight;

    if (!position) {
      const viewportHeight = document.documentElement.offsetHeight;
      const enougthPlaceOnBottom = viewportHeight - relativeRect.bottom > elementHeight;
      if (enougthPlaceOnBottom) {
        this.renderer.setStyle(element, 'top', `${pxToRem(relativeRect.bottom)}rem`);
      } else {
        this.renderer.setStyle(
          element,
          'top',
          `${pxToRem(relativeRect.top - element.offsetHeight)}rem`,
        );
      }

      return;
    }

    switch (position) {
      case 'top-inner':
        this.renderer.setStyle(element, 'top', `${pxToRem(relativeRect.top)}rem`);
        break;
      case 'top-outer':
        this.renderer.setStyle(
          element,
          'top',
          `${pxToRem(relativeRect.top - element.offsetHeight)}rem`,
        );
        break;
      case 'bottom-inner':
        this.renderer.setStyle(
          element,
          'top',
          `${pxToRem(relativeRect.bottom - element.offsetHeight)}rem`,
        );
        break;
      case 'bottom-outer':
        this.renderer.setStyle(element, 'top', `${pxToRem(relativeRect.bottom)}rem`);
        break;
      default:
        break;
    }
  }

  /**
   * The horizontal position of the element will be based on xPosition.
   * If not provided, the element will have a left-inner position
   */
  private positionHorizontally(
    element: HTMLElement,
    relativeRect: DOMRect,
    position?: XPosition,
  ): void {
    const elementWidth = element.offsetWidth;
    const viewportWidth = document.documentElement.offsetWidth;

    if (!position) {
      const enougthPlaceOnRightSide = viewportWidth - relativeRect.right > elementWidth;
      if (enougthPlaceOnRightSide) {
        this.renderer.setStyle(element, 'left', `${pxToRem(relativeRect.left)}rem`);
      } else {
        this.renderer.setStyle(
          element,
          'left',
          `${pxToRem(relativeRect.right - element.offsetWidth)}rem`,
        );
      }

      return;
    }

    switch (position) {
      case 'left-inner':
        this.renderer.setStyle(element, 'left', `${pxToRem(relativeRect.left)}rem`);
        break;
      case 'left-outer':
        this.renderer.setStyle(
          element,
          'left',
          `${pxToRem(relativeRect.left - element.offsetWidth)}rem`,
        );
        break;
      case 'right-inner':
        this.renderer.setStyle(
          element,
          'left',
          `${pxToRem(relativeRect.right - element.offsetWidth)}rem`,
        );
        break;
      case 'right-outer':
        this.renderer.setStyle(element, 'left', `${pxToRem(relativeRect.right)}rem`);
        break;
      default:
        this.renderer.setStyle(element, 'left', `${pxToRem(relativeRect.left)}rem`);
        break;
    }
  }

  /**
   * When adding to the viewport, the menu may not have its final height, for example when the elements are loaded asynchronously.
   * In this case, the position may be off.
   * This method allows to check if there is a resize of the menu.
   * We only have to check once for the initial population of the menu, thus we unobserve as soon as the reposition is done.
   */
  private observeResizeOf(element: HTMLElement, position: Position) {
    this.resizeObserver = new ResizeObserver(() => {
      this.setElementPosition(element, position);
      this.destroyResizeObserver();
    });

    this.resizeObserver.observe(element);
  }

  private destroyResizeObserver(): void {
    this.resizeObserver?.disconnect();
  }
}
