/* eslint-disable import/no-cycle */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { Component, forwardRef, HostBinding, Inject, Input } from '@angular/core';

import { NotificationType } from './notification';
import { NotificationService } from './notification.service';
import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent],
  selector: 'smw-notification',
  template: `
    <p class="title caption-md mb-1" *ngIf="title">{{ title }}</p>
    <p class="message body-md mb-0">{{ message }}</p>

    <smw-icon icon="close" (click)="close()"></smw-icon>
  `,
  styleUrls: ['./notification.component.less'],
})
export class NotificationComponent {
  @Input() title?: string;
  @Input() message!: string;
  @Input() type: NotificationType = 'info';

  @HostBinding('class') get notificationClass(): string {
    return this.type;
  }

  constructor(
    @Inject(forwardRef(() => NotificationService))
    private service: NotificationService,
  ) {}

  close() {
    this.service.close();
  }
}
