@if (label(); as label) {
  <label [htmlFor]="inputId" class="caption-sm">{{ label }}</label>
}

<input
  #input
  [type]="type()"
  [attr.id]="inputId"
  class="body-md"
  [class.invalid]="invalid()"
  [className]="specialStyle()"
  [placeholder]="placeholder()"
  (keypress)="onChange($any($event.target).value)"
  (change)="onChange($any($event.target).value)"
/>
