import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class IconCache {
  private cache = new Map<string, HttpResponse<unknown>>();

  get(path: string): HttpResponse<unknown> | undefined {
    return this.cache.get(path);
  }

  put(path: string, response: HttpResponse<unknown>): void {
    this.cache.set(path, response);
  }
}
