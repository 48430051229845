import { computed, Injectable, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs';

import { extractDataFromRouteTree, isNavigationEnd } from '@smw/navigation-front';

export type Space = 'collaborator' | 'manager' | 'hr';

@Injectable({ providedIn: 'root' })
export class SpaceStore {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events.pipe(filter(isNavigationEnd)).subscribe(() => {
      const data = extractDataFromRouteTree(this.route.root);
      const space = data['space'];

      switch (space) {
        case 'collaborator':
          this.mode.set('collaborator');
          break;
        case 'manager':
          this.mode.set('manager');
          break;
        case 'hr':
          this.mode.set('hr');
          break;
        default:
          break;
      }
    });
  }

  mode = signal<Space>('collaborator');

  viewingAsCollaborator = computed(() => this.mode() === 'collaborator');
  viewingAsManager = computed(() => this.mode() === 'manager');
}
