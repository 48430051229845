import { ChangeDetectionStrategy, Component, viewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'smw-overlay',
  standalone: true,
  templateUrl: './overlay.component.html',
  styleUrl: './overlay.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent {
  containerRef = viewChild('container', { read: ViewContainerRef });
}
