/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'smw-password',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordComponent,
      multi: true,
    },
  ],
})
export class PasswordComponent implements ControlValueAccessor {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() invalid = false;

  @ViewChild('input', { static: true, read: ElementRef })
  inputField!: ElementRef<HTMLInputElement>;

  inputId?: string;
  value = '';

  isHidden = true;

  onChange!: (value: string) => void;
  onTouched!: () => void;
  onValidationChange!: () => void;

  constructor(
    @Attribute('id') public id: string,
    private element: ElementRef,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
  ) {
    if (this.id) {
      this.inputId = this.id;
      this.renderer.removeAttribute(this.element.nativeElement, 'id');
    }
  }

  writeValue(value: string): void {
    this.value = value;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.inputField) {
      this.renderer.setProperty(this.inputField.nativeElement, 'disabled', isDisabled);
    }
  }
}
