import { HttpParams } from '@angular/common/http';

export class HttpParamsBuilder<T> {
  private params = new HttpParams();

  append(param: keyof T, value: string | number | boolean | undefined | string[]): this {
    if (value) {
      if (value instanceof Array) {
        value.forEach((item) => (this.params = this.params.append(param as string, item)));
      } else {
        this.params = this.params.append(param as string, value);
      }
    }
    return this;
  }

  build(): HttpParams {
    return this.params;
  }
}
