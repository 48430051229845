import { IsString } from 'class-validator';

import { transformAndValidate } from '@smw/common-transformers';

export class AppConfigDto {
  @IsString()
  SEGMENT_WRITE_KEY!: string;

  @IsString()
  SEGMENT_CDN_URL!: string;

  @IsString()
  SEGMENT_API_HOST!: string;
}

export type AppConfig = {
  segmentWriteKey: string;
  segmentCdnUrl: string;
  segmentApiHost: string;
};

export const appConfigValidator = transformAndValidate(AppConfigDto);

export const getConfig = (appConfig: unknown): AppConfig => {
  const config = appConfigValidator(appConfig);

  return {
    segmentWriteKey: config.SEGMENT_WRITE_KEY,
    segmentCdnUrl: config.SEGMENT_CDN_URL,
    segmentApiHost: config.SEGMENT_API_HOST,
  };
};
