@if (label) {
  <label [htmlFor]="inputId" class="caption-sm">{{ label }}</label>
}
<div class="box" [class.invalid]="invalid">
  <input
    #input
    class="body-md"
    autocomplete="current-password"
    [type]="isHidden ? 'password' : 'text'"
    [attr.id]="inputId"
    [value]="value"
    [placeholder]="placeholder"
    (change)="onChange($any($event.target).value)"
  />
  <smw-icon
    [icon]="isHidden ? 'eyeFilled' : 'eyeInvisible'"
    (click)="isHidden = !isHidden"
  ></smw-icon>
</div>
