/* eslint-disable no-plusplus */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'range', standalone: true, pure: true })
export class RangePipe implements PipeTransform {
  transform(_: [], size: number, start = 0) {
    const result = [];
    for (let i = start; i < start + size; i++) {
      result.push(i);
    }
    return result;
  }
}
