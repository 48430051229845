import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export type CallbackURI = {
  pathname: string;
  queryParams: { [key: string]: string };
};

/**
 * CallbackUrl is a the target anonymous users attempt to reach before being redirected to authentication pages.
 * It allows to send them back to this target once authenticated instead of being redirected to the dashboard.
 *
 * We've decided to use localStorage here to persist the CallbackUrl instead of passing it using query string
 * to ensure it survives any authenticated process in-between (like password reset).
 */
@Injectable({ providedIn: 'root' })
export class CallbackUrlService {
  constructor(private router: Router) {}

  register(callbackUrl: string): void {
    localStorage.setItem('callbackUrl', callbackUrl);
  }

  navigateToCallbackUrlOrFallback(fallback: string): void {
    const callbackUrl = localStorage.getItem('callbackUrl') ?? fallback;
    this.router.navigateByUrl(callbackUrl).then(() => localStorage.removeItem('callbackUrl'));
  }

  getCallbackUrl(fallback: string): CallbackURI {
    const storedURI = localStorage.getItem('callbackUrl');
    if (!storedURI) {
      return this.buildCallbackURI(fallback);
    }

    return this.buildCallbackURI(storedURI);
  }

  clear(): void {
    localStorage.removeItem('callbackUrl');
  }

  private buildCallbackURI(uri: string): CallbackURI {
    const url = new URL(uri, window.location.origin);
    return {
      pathname: url.pathname,
      queryParams: this.extractQueryParams(url.searchParams),
    };
  }

  private extractQueryParams(seachParams: URLSearchParams): { [key: string]: string } {
    const queryParams: { [key: string]: string } = {};

    seachParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }
}
