export function hexToRGB(hex: string): string {
  if (hex[0] !== '#') {
    return hex;
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgb(${r}, ${g}, ${b})`;
}

export function rgbOf(colorVariable: string): string | undefined {
  const hex = getComputedStyle(document.body).getPropertyValue(colorVariable);
  if (!hex) {
    return undefined;
  }

  return hexToRGB(hex);
}

/**
 * Generate a HSL color based on a string
 *
 * Default saturation and lightness give vibrant color but not too shiny and harsh for the eyes.
 * This tool https://www.w3schools.com/colors/colors_hsl.asp
 * will help to find combination fitting your needs if required.
 *
 * This code comes from https://stackoverflow.com/a/21682946.
 * */
export function stringToHSL(value: string, saturation = 66, lightness = 50): string {
  let hash = 0;

  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
}
