import { Pipe, PipeTransform } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { ModeType } from '@smw/common-business';

@Pipe({
  name: 'modeDescription',
  standalone: true,
})
export class ModeDescriptionPipe implements PipeTransform {
  transform(mode: ModeType): string {
    switch (mode) {
      case 'introspection':
        return marker('mode-detail-introspection-tagline');
      case 'impact':
        return marker('mode-detail-impact-tagline');
      case 'equilibre':
        return marker('mode-detail-equilibre-tagline');
      case 'ascension':
        return marker('mode-detail-ascension-tagline');
      case 'transformation':
        return marker('mode-detail-transformation-tagline');
      default:
        return '';
    }
  }
}
