import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, args?: unknown): string {
    if (args && args === 'style') {
      return this.sanitizer.bypassSecurityTrustStyle(value) as string;
    }
    if (args && args === 'html') {
      return this.sanitizer.sanitize(SecurityContext.HTML, value) ?? value;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(value) as string;
  }
}
