<div class="sidebar-header">
  <a class="root-link" [routerLink]="['collaborator' | route]" [title]="'Link to dashboard'">
    @if (mediumDevice()) {
      <smw-icon class="logo" icon="logo" [title]="'somanyWays Logo'" />
    } @else {
      <img class="logo" [src]="'/assets/images/logo-smw-alt.png'" [title]="'somanyWays Logo'" />
    }
  </a>

  <button smw-button kind="icon" icon="close" class="close" (click)="close.emit()"></button>
</div>

<ul class="nav-items">
  @for (item of items(); track $index) {
    <li class="nav-item">
      <a
        class="nav-link"
        [class.disabled]="item.disabled"
        [class.active2]="fixIncorrectActiveLinks(item.path)"
        [routerLink]="item.disabled ? null : [item.path]"
        [routerLinkActive]="'active'"
        (click)="close.emit()"
      >
        <smw-icon class="nav-icon" [icon]="item.icon"></smw-icon>
        <span class="title-xxs">
          {{ item.label | translate }}
        </span>
      </a>
    </li>
  }
</ul>

<div class="menu-trigger-wrapper">
  <div
    class="menu-trigger"
    [class.active]="isMenuOpened()"
    [menuTriggerFor]="menu"
    [xPosition]="smallDevice() ? 'left-inner' : 'right-outer'"
    [yPosition]="'bottom-inner'"
    (click)="isMenuOpened.set(true)"
  >
    <div class="body-md initials">{{ initials() }}</div>
    @if (!mediumDevice()) {
      <div>
        <div class="title-xxs fullname">{{ fullname() }}</div>
        <div class="body-md">{{ viewingAs() | translate }}</div>
      </div>
      <smw-icon [icon]="isMenuOpened() ? 'sidebar-menu-inverted' : 'sidebar-menu'"></smw-icon>
    }
  </div>
</div>

<ng-template #menu>
  <smw-sidebar-menu
    menu
    [company]="company()"
    [roles]="roles()"
    (logout)="logout()"
    (close)="isMenuOpened.set(false)"
    (closeSidebar)="close.emit()"
  ></smw-sidebar-menu>
</ng-template>
