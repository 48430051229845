import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IconComponent } from '../../icon/icon.component';
import { NotificationService } from '../../notification/notification.service';

@Component({
  standalone: true,
  selector: 'smw-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.less'],
  imports: [IconComponent, IconComponent],
})
export class EmailsComponent {
  emails = signal<string[]>([]);

  effectivePlaceholder = computed(() => (this.emails()?.length > 0 ? '' : this.placeholder()));
  placeholder = input('');
  emailLimit = input<number>(99);

  @ViewChild('emailInput') emailInput!: ElementRef<HTMLInputElement>;
  @ViewChild('emailListing') emailcontainer!: ElementRef<HTMLDivElement>;

  @Output() emailChange = new EventEmitter();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  onKeyDown(event: KeyboardEvent) {
    const inputValue = this.emailInput?.nativeElement?.value?.trim();

    if (event.key === 'enter' || event.key === ',' || event.key === 'Tab' || event.key === ' ') {
      event.preventDefault();
      if (inputValue) {
        this.addEmail(inputValue);
      }
    } else if (event.key === 'Backspace' && !inputValue && this.emails.length) {
      event.preventDefault();
      this.removeEmail(this.emails.length - 1);
    }
  }

  addEmail(email: string) {
    if (this.emailLimit() && this.emails().length >= this.emailLimit()) {
      this.notificationService.error({
        message: this.translateService.instant('email_component_maximum_reached'),
      });
      return;
    }
    if (this.isValidEmail(email) && !this.emails().includes(email)) {
      this.emails.update((currentEmails) => [...currentEmails, email]);
      this.emailInput.nativeElement.value = '';
      this.emailChange.emit(this.emails);
    }
  }

  removeEmail(index: number) {
    console.log('EMAIL REMOVE');

    this.emails.update((currentEmails) => [
      ...currentEmails.slice(0, index),
      ...currentEmails.slice(index + 1),
    ]);
    this.emailChange.emit(this.emails);
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  }
}
