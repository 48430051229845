// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual(value: any, other: any): boolean {
  if (value === other) {
    return true;
  }

  if (typeof value !== 'object' || value === null || typeof other !== 'object' || other === null) {
    return false;
  }

  const valueKeys = Object.keys(value);
  const otherKeys = Object.keys(other);

  if (valueKeys.length !== otherKeys.length) {
    return false;
  }

  for (const key of valueKeys) {
    if (!otherKeys.includes(key)) {
      return false;
    }

    if (typeof value[key] === 'function' && typeof other[key] === 'function') {
      continue;
    }

    if (!deepEqual(value[key], other[key])) {
      return false;
    }
  }

  return true;
}
