@if (label()) {
  <label class="caption-sm">{{ label() }}</label>
}

<textarea
  #input
  class="body-md"
  [placeholder]="placeholder()"
  (keyup)="updateValue($any($event.target).value ?? '')"
></textarea>
