import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, take, takeUntil } from 'rxjs';

import { ANALYTICS, Analytics, EventIdentifier } from '@smw/analytics-front';
import { User } from '@smw/common-models';
import { RoutePipe } from '@smw/navigation-front';
import {
  ButtonComponent,
  IconComponent,
  PasswordComponent,
  TextFieldComponent,
} from '@smw/ui-front';

import { AuthTemplateComponent } from '../../components/template.component';
import { AuthenticationService } from '../../services';
import { CallbackUrlService } from '../../services/callback-url.service';

type LoginForm = FormGroup<{
  email: FormControl<string>;
  password: FormControl<string>;
}>;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    TranslateModule,
    IconComponent,
    TextFieldComponent,
    PasswordComponent,
    ButtonComponent,
    AuthTemplateComponent,
    RoutePipe,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  form?: LoginForm;
  isInvalid = signal(true);
  hasFormError = signal(false);
  isEmailInvalid = signal(false);
  formRequestErrorMessage = '';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private callbackUrlService: CallbackUrlService,
    private authService: AuthenticationService,
    @Inject(ANALYTICS) private analytics: Analytics,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, this.validateEmailDomain()],
      }),
      password: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.hasFormError.set(false);
      this.changeDetector.markForCheck();
    });

    this.form.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((status) => {
      this.isInvalid.set(status === 'INVALID');
    });

    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit(): void {
    this.formRequestErrorMessage = '';
    this.hasFormError.set(false);

    if (this.form?.invalid) {
      this.hasFormError.set(true);
      return;
    }

    this.isInvalid.set(true);
    this.changeDetector.markForCheck();

    const formValue = this.form!.getRawValue();

    this.authService
      .login(formValue.email, formValue.password)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe({
        next: (user) => {
          if (user) {
            this.track(user);
            this.callbackUrlService.navigateToCallbackUrlOrFallback('/');
            this.callbackUrlService.clear();
          } else {
            this.hasFormError.set(true);
          }
        },
        error: () => {
          this.hasFormError.set(true);
          this.formRequestErrorMessage = marker('auth_login_error');
          this.changeDetector.markForCheck();
        },
      });

    this.isInvalid.set(false);
    this.changeDetector.markForCheck();
  }

  validateEmailDomain(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.isEmailInvalid.set(false);
      const pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      let email = control.value as string;
      email = email.replace(' ', '');

      if (email.length === 0) {
        return null;
      }

      const isValid = pattern.test(email);

      this.isEmailInvalid.set(!isValid);

      return !isValid ? { invalidDomain: { value: control.value } } : null;
    };
  }

  private track(user: User) {
    this.analytics.identify(user.id, {
      email: user.email,
      tenant: user.client?.subDomain,
      roles: ['user'],
      firstname: user.firstname,
      lastname: user.lastname ?? undefined,
      businessService: user.businessServiceId ?? undefined,
      jobResponsabilities: user.jobResponsabilities ?? undefined,
      newsletterConsent: user.newsletterConsent ?? false,
      clientId: user.client?.id,
      clientName: user.client?.name,
      isRealUser: user.isRealUser,
    });
    this.analytics.track({ id: EventIdentifier.UserSignedIn });
  }
}
