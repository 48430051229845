import { Component, computed, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'smw-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
})
export class TooltipComponent {
  text = input.required<string>();
  show = input(false);
  disabled = input(false);

  color = '#0E153F';

  position = input('top');

  isEnabled = computed(() => !this.disabled());
}
