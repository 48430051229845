/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-card-badge',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: `<smw-icon [icon]="icon"></smw-icon>`,
  styleUrls: ['./card-badge.component.less'],
})
export class CardBadgeComponent {
  @Input() icon!: string;
  @Input() disabled = false;

  @HostBinding('class.disabled') get disabledClass(): boolean {
    return !!this.disabled;
  }

  @HostBinding('style.background-color') get backgroundColor() {
    if (this.disabled) {
      return 'var(--primary-50)';
    }

    return 'var(--secondary-green-100)';
  }
}
