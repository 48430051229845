import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  model,
  Renderer2,
  viewChild,
} from '@angular/core';

import { RangePipe } from '@smw/utils-front';

@Component({
  standalone: true,
  selector: 'smw-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.less'],
  imports: [RangePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  constructor(private renderer: Renderer2) {}

  unit = input<string>('%');
  stepsCount = input<number>(5);
  showStepsIndicators = input<boolean>(false);
  maxValue = input<number>(100);
  currentValue = model<number>(0);

  fixedStepsCount = computed(() => (this.stepsCount() <= 2 ? 2 : this.stepsCount()));
  stepPercents = computed(() => {
    const percents: number[] = [];
    const count = this.fixedStepsCount();
    for (let i = 0; i < count; i++) {
      percents.push(i * (100 / (count - 1)));
    }

    return percents;
  });
  stepValues = computed(() => {
    const max = this.maxValue();
    return this.stepPercents().map((item) => Math.round((max * item) / 100));
  });
  currentStep = computed(() => {
    const index = this.stepValues().indexOf(this.currentValue());
    return index === -1 ? 0 : index;
  });

  progressContainerRef = viewChild<ElementRef<HTMLElement>>('progress');
  trackElementRef = viewChild<ElementRef<HTMLElement>>('track');

  showHandle = computed(() => this.showStepsIndicators());

  updateTrack = effect(() => {
    const element = this.trackElementRef()?.nativeElement;
    if (!element) {
      return;
    }

    const trackWidth = (this.currentValue() * 100) / this.maxValue();
    if (!this.showHandle() && trackWidth < 10) {
      this.renderer.setStyle(element, 'width', '1.5rem');
      return;
    }

    this.renderer.setStyle(element, 'width', `${trackWidth}%`);
  });

  onDrag(event: MouseEvent | TouchEvent, controller: 'mouse' | 'touch'): void {
    event.preventDefault();

    const track = this.trackElementRef()?.nativeElement;
    const progressContainer = this.progressContainerRef()?.nativeElement;
    if (!track || !progressContainer) {
      return;
    }

    const containerRect = progressContainer.getBoundingClientRect();

    const onMove = (e: MouseEvent | TouchEvent) => {
      const lowerBound = containerRect.left;
      const upperBound = containerRect.right;
      const mouseX =
        controller === 'mouse' ? (e as MouseEvent).clientX : (e as TouchEvent).touches[0].clientX;

      if (mouseX < lowerBound) {
        this.updateValue(0);
        return;
      }

      if (mouseX > upperBound) {
        this.updateValue(this.maxValue());
        return;
      }

      const offsetX = mouseX - lowerBound;
      const value = Math.ceil((offsetX / containerRect.width) * 100);

      if (this.stepValues().includes(value)) {
        this.updateValue(value);
        return;
      }
    };

    const onRelease = () => {
      this.renderer.setStyle(window.document.body, 'cursor', 'auto');
      if (controller === 'mouse') {
        window.removeEventListener('mousemove', onMove);
        window.removeEventListener('mouseup', onRelease);
      } else {
        window.removeEventListener('touchmove', onMove);
        window.removeEventListener('touchend', onRelease);
      }
    };

    this.renderer.setStyle(window.document.body, 'cursor', 'grabbing');
    if (controller === 'mouse') {
      window.addEventListener('mousemove', onMove);
      window.addEventListener('mouseup', onRelease);
    } else {
      window.addEventListener('touchmove', onMove);
      window.addEventListener('touchend', onRelease);
    }
  }

  private updateValue(value: number) {
    this.currentValue.set(value);
  }

  changeProgress(index: number): void {
    this.currentValue.set(this.stepValues()[index]);
  }
}
