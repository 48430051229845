import { Component, Input } from '@angular/core';

@Component({
  selector: 'smw-loader',
  standalone: true,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
})
export class LoaderComponent {
  @Input() loaderSize: number = 5;
  @Input() loaderShow: boolean = true;
}
