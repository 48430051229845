<div class="left">
  <div class="main-content">
    <ng-content></ng-content>
  </div>
</div>

<div class="right">
  <img
    src="/assets/images/authentication-page.png"
    srcset="
      /assets/images/authentication-page.png,
      /assets/images/authentication-page_2x.png 2x,
      /assets/images/authentication-page_3x.png 3x
    "
  />
</div>
