import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  transform(value: number, style: 'short' | 'long' = 'short'): string {
    return Duration.fromObject({ seconds: value }).rescale().toHuman({ unitDisplay: style });
  }
}
