import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'smw-checkbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  label = input.required<string | TemplateRef<unknown>>();
  templateLabelContext = input<unknown>();
  checked = model(false);
  disabled = model(false);

  private value: unknown;

  templateLabel = computed(() => {
    const label = this.label();
    if (label instanceof TemplateRef) {
      return label;
    }

    return undefined;
  });

  onChange?: (value: unknown) => void;
  onTouched?: () => void;
  onValidationChange!: () => void;

  writeValue(value: unknown): void {
    this.value = value;
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  changeValue(): void {
    if (this.disabled()) {
      return;
    }

    const checked = !this.checked();
    this.checked.set(checked);
    this.onChange?.(this.value);
  }
}
