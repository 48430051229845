import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-modal',
  standalone: true,
  imports: [TranslateModule, ButtonComponent, IconComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  title = input.required<string>();
  showClose = input<boolean>(true);

  cancelLabel = input<string>();
  confirmLabel = input<string>();

  cancel = output();
  confirm = output();

  constructor() {}
}
