import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'timeAgo', standalone: true, pure: true })
export class TimeAgoPipe implements PipeTransform {
  transform(value: string): string {
    const datetime = DateTime.fromISO(value);

    if (!datetime.isValid) {
      return value;
    }

    return datetime.toRelative() ?? value;
  }
}
