import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export function minDate(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (control.value < DateTime.fromJSDate(date).startOf('day')) {
      return { minDate: true };
    }

    return null;
  };
}
