/* eslint-disable import/no-cycle */
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Injectable,
} from '@angular/core';

import { Notification, NotificationType } from './notification';
import { NotificationComponent } from './notification.component';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notificationRef!: ComponentRef<NotificationComponent>;

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
  ) {}

  info(notification: Notification) {
    this.open('info', notification);
  }

  success(notification: Notification) {
    this.open('success', notification);
  }

  error(notification: Notification) {
    this.open('error', notification);
  }

  private open(type: NotificationType, notification: Notification) {
    this.notificationRef = createComponent(NotificationComponent, {
      environmentInjector: this.injector,
    });

    this.notificationRef.setInput('type', type);
    this.notificationRef.setInput('message', notification.message);

    if (notification.title) {
      this.notificationRef.setInput('title', notification.title);
    }

    document.body.appendChild(this.notificationRef.location.nativeElement);

    this.appRef.attachView(this.notificationRef.hostView);

    setTimeout(() => {
      this.close();
    }, notification.duration ?? 5000);
  }

  close() {
    if (this.notificationRef) {
      this.appRef.detachView(this.notificationRef.hostView);
      this.notificationRef.destroy();
    }
  }
}
