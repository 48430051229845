import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { RoutePipe } from '@smw/navigation-front';
import { ButtonComponent, IconComponent, PasswordComponent } from '@smw/ui-front';

import { AuthTemplateComponent } from '../../components/template.component';
import { passwordRegexp } from '../../helpers/password-strengh';
import { AuthenticationService } from '../../services';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
    TranslateModule,
    ButtonComponent,
    PasswordComponent,
    AuthTemplateComponent,
    IconComponent,
    RoutePipe,
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  form?: FormGroup<{ password: FormControl<string> }>;
  passwordReset = false;
  isProcessing = false;

  resetPasswordErrorMessage = signal('');

  private resetCode = '';

  constructor(
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
  ) {}

  get isDisabled(): boolean {
    return this.form?.invalid || this.isProcessing;
  }

  ngOnInit(): void {
    const { code } = this.route.snapshot.queryParams as { code: string };
    this.resetCode = code;

    this.form = new FormGroup({
      password: new FormControl('', {
        validators: [Validators.required, Validators.pattern(passwordRegexp)],
        nonNullable: true,
      }),
    });

    this.changeDetector.markForCheck();
  }

  submit(): void {
    this.resetPasswordErrorMessage.set('');

    if (this.form?.invalid) {
      return;
    }
    const { password } = this.form!.getRawValue();

    this.authenticationService
      .resetPassword(password, this.resetCode)

      .subscribe({
        next: () => {
          this.passwordReset = true;
          this.isProcessing = false;
          this.changeDetector.markForCheck();
        },
        error: (err: HttpErrorResponse) => {
          this.isProcessing = false;
          this.changeDetector.markForCheck();

          if (err.error.message === 'Invalid reset code') {
            this.resetPasswordErrorMessage.set(
              this.translate.instant('auth_reset_invalid-reset-code'),
            );
            return;
          } else {
            this.resetPasswordErrorMessage.set(this.translate.instant(`auth_reset_error`));
          }
        },
      });
  }
}
