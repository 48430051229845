/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

const ModeColors: Record<string, string> = {
  Ascension: 'var(--ascension)',
  Équilibre: 'var(--equilibre)',
  Impact: 'var(--impact)',
  Introspection: 'var(--introspection)',
  Transformation: 'var(--transformation)',
};

@Directive({ selector: '[mode]', standalone: true })
export class ModeColorDirective implements OnChanges {
  @Input() mode!: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(): void {
    let color = ModeColors[this.mode];

    if (!color) {
      color = 'var(--primary-400)';
    }

    this.renderer.setStyle(this.elementRef.nativeElement, 'color', color);
  }
}
