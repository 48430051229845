export const MobilityExercise = {
  mobilityContent1: 'mobilityContent1',
  mobilityContent2: 'mobilityContent2',
  mobilityContent3: 'mobilityContent3',
  mobilityContent4: 'mobilityContent4',
  mobilityContent5: 'mobilityContent5',
  mobilityContent6: 'mobilityContent6',
  mobilityContent7: 'mobilityContent7',
  mobilityContent8: 'mobilityContent8',
  mobilityContent9: 'mobilityContent9',
  mobilityContent10: 'mobilityContent10',
  mobilityContent11: 'mobilityContent11',
  mobilityContent12: 'mobilityContent12',
} as const;

export type MobilityExercise = (typeof MobilityExercise)[keyof typeof MobilityExercise];
